import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { Box, Grid, useThemeUI } from 'theme-ui';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Heading from '../components/Heading';
import Richtext from '../components/Richtext';
import Layout from '../global/Layout';

const About = ({ data }) => {
  const {
    theme: { colors },
  } = useThemeUI();
  return (
    <Layout background="blue">
      <Helmet>
        <meta name="theme-color" content={colors.blue} />
      </Helmet>
      <Header appearance="light" />

      <Grid columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']} gap={[3, 4]}>
        <Box
          sx={{
            gridColumn: ['1/ span 4', '4/ span 6'],
            color: 'white',
            mt: [10, 20],
            mb: [10, 20],
          }}
        >
          <Heading as="h2" variant="h1" color="pig" sx={{ mb: [3, 5] }}>
            {data.sanityPages.heading}
          </Heading>
          <Richtext
            content={data.sanityPages._rawCopy}
            sx={{ color: 'white' }}
          />
        </Box>
      </Grid>
      <Footer />
    </Layout>
  );
};
export const query = graphql`
  {
    sanityPages(slug: { current: { eq: "about" } }) {
      _rawCopy
      heading
      title
    }
  }
`;
export default About;
